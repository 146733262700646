<template>
  <v-app>
    <v-row class="mb-10" dense align="center">
      <v-img
        :max-height="height_log1"
        :max-width="width_log1"
        :src="logo_1"
      ></v-img>
      <h2 class="ml-5">Leads bradescard</h2>
      <v-spacer />
      <v-img
        :max-height="width_log2"
        :max-width="height_log2"
        :src="logo_2"
      ></v-img>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BuscarLead />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import BuscarLead from "./BuscarLead.vue";

import LogoSegurify from "@/assets/logo_segurify.png";
import LogoBradescard from "@/assets/logo-bradescard-horizontal.png";

export default {
  components: {
    BuscarLead,
  },
  data() {
    return {
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,
      width_log2: 80,
      height_log2: 250,
    };
  },
  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
    puedeCrear() {
      const rolesValidos = [
        "ADMINCC",
        "MANAGERCC",
        "DIRECTO",
        "ADMIN",
        "ADMINAGENT",
        "MANAGER",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },
  },
  methods: {
    leadCreado() {
      this.showCreacion = false;
      this.bus.$emit("reload");
    },
  },
};
</script>
