<template>
  <div>
    <v-card>
      <v-toolbar dense flat dark color="#133B7D">
        <v-toolbar-title>
          <span>Agendas del día</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          no-data-text="Sin agendas para mostrar"
          :footer-props="{
            'items-per-page-all-text': 'Todos',
            'items-per-page-text': 'Elementos por página',
          }"
          disable-sort
        >
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            <div v-if="itemsLength">
              Mostrando {{ pageStart }} - {{ pageStop }} de
              {{ itemsLength }} leads
            </div>
          </template>
          <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
        },
        {
          text: "Producto",
          align: "center",
          value: "producto",
        },
        {
          text: "Fecha de llamada agendada",
          align: "center",
          value: "proxima_llamada",
        },
        {
          text: "Ver",
          align: "end",
          value: "view",
        },
      ],
      data: [],
      agenteAccessToken: localStorage.getItem("agenteAccessToken"),
    };
  },
  mounted() {
    this.getAgenda();
  },
  methods: {
    getAgenda() {
      const config = {
        headers: {
          Authorization: "Bearer " + this.agenteAccessToken,
        },
      };
      this.$axios
        .get(`/v1/bradescard/agenda/${localStorage.agenteUuid}`, config)
        .then((response) => {
          if (!response.data[0]) return;
          response.data
            .sort((a, b) => {
              const milisA = new Date(a.proximaLlamada).getTime();
              const milisB = new Date(b.proximaLlamada).getTime();
              if (milisA < milisB) return -1;
              if (milisB < milisA) return 1;
              return 0;
            })
            .map((element) => {
              this.data.push({
                producto: element.producto,
                nombre: element.nombre,
                uuid: element.uuid,
                proxima_llamada: element.proximaLlamada
                  ? moment.utc(element.proximaLlamada).format("DD/MM/YYYY")
                  : "No Disponible",
              });
            });
        });
    },
    changeRoute(item) {
      this.$router.push({
        name: "DetalleLeadBradescard",
        params: { uuid: item.uuid },
      });
    },
  },
};
</script>
