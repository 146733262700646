<template>
  <div>
    <v-card>
      <v-toolbar dense dark flat color="#133B7D">
        <v-toolbar-title>
          <span>Resultados de búsqueda</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :items="leads"
          :headers="headers"
          no-data-text="Sin leads para mostrar"
          loading-text="Buscando leads..."
          :loading="loading"
          :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
          :server-items-length="totalRegistros"
          @update:page="pagina = $event"
          @update:items-per-page="elementosPagina = $event"
        >
          <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            <div v-if="itemsLength">
              Mostrando {{ pageStart }} - {{ pageStop }} de
              {{ itemsLength }} leads
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    leads: {
      type: Array,
      default: () => [],
    },
    totalRegistros:{
      type: Number,
      default:0
    }
  },
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "center",
          value: "id",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Etiqueta",
          align: "center",
          value: "etiqueta",
        },
        {
          text: "Producto",
          align: "center",
          value: "producto",
        },
        {
          text: "Producto Aseguradora",
          align: "center",
          value: "productoAseguradora",
        },
        {
          text: "4UD",
          align: "center",
          value: "fourUD",
        },
        {
          text: "Última disposición",
          align: "center",
          value: "ultimaDisposicion",
        },
        {
          text: "Operador",
          align: "center",
          value: "operador",
        },
        {
          text: "Ver",
          align: "end",
          value: "view",
        },
      ],
      elementosPagina:10,
      pagina:1,
    };
  },
  watch: {
    pagina(v) {
      if(v)
        this.$emit('paginacionModificada', {
          'pagina': v,
          'registrosPagina': this.elementosPagina > 0 ? this.elementosPagina : this.totalRegistros,
        })
    },

    elementosPagina(v) {
      if(v)
        this.$emit('paginacionModificada', {
          'pagina': this.pagina,
          'registrosPagina': v > 0 ? v : this.totalRegistros,
        })
    },
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: "DetalleLeadBradescard",
        params: { uuid: item.uuid },
        props: {isInbound: item.isInbound}
      });
    },
  },
};
</script>
