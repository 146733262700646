<template>
  <div>
    <v-card>
      <v-toolbar dense flat dark color="#133B7D">
        <v-toolbar-title>
          <span>Buscar por</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="formularioValido" lazy-validation>
          <v-row dense>
            <v-col v-if="error" cols="12">
              <v-alert type="error" dense>{{ error }}</v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="nombre"
                outlined
                dense
                label="Nombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="apellidoPaterno"
                outlined
                dense
                label="Apellido Paterno"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="apellidoMaterno"
                outlined
                dense
                label="Apellido Materno"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="fechaNacimiento"
                outlined
                dense
                label="Fecha de nacimiento"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="rfc"
                outlined
                dense
                label="RFC"
                :rules="[$rules.rfc]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="telefono"
                outlined
                dense
                label="Teléfono"
                prepend-inner-icon="mdi-phone"
                counter="10"
                :rules="[$rules.numeric, longDigitValidation(10)]"
                :maxLength="10"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="email"
                outlined
                dense
                label="Correo electrónico"
                prepend-inner-icon="mdi-email"
                :rules="[$rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="producto_aseguradora == ''">
              <v-alert dense text type="info"
                >Para que su selección sea más específica seleccione un Producto
                Aseguradora</v-alert
              >
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="producto_aseguradora"
                outlined
                :items="productos_aseguradora"
                dense
                label="Producto Aseguradora"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="producto_aseguradora == 'Asistencias'"
            >
              <v-text-field
                v-model="tarjeta"
                outlined
                dense
                counter="16"
                :maxLength="16"
                :rules="[$rules.numeric, longDigitValidation(16)]"
                label="Tarjeta"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div>
          <v-btn
            color="primary"
            :disabled="!formularioValido"
            :loading="loading"
            @click="aplicarFiltros()"
          >
            <v-icon small class="mr-1">mdi-magnify</v-icon>
            <span>Aplicar filtros</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios";
import RfcFacil from "rfc-facil";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: "",
      telefono: "",
      email: "",
      rfc: "",
      formularioValido: true,
      error: "",
      producto_aseguradora: "",
      longDigitValidation(number) {
        return (v) =>
          !v ||
          v.length == number ||
          "Debe proporcionar " + number + " digitos";
      },
      productos_aseguradora: [],
    };
  },

  computed: {
    shouldCalculateRfc() {
      return (
        !!this.nombre &&
        !!this.apellidoPaterno &&
        !!this.apellidoMaterno &&
        !!this.fechaNacimiento
      );
    },
  },

  methods: {
    aplicarFiltros() {
      if (!this.producto_aseguradora) {
        this.$toast.error("El producto aseguradora es un campo requerido");
        return;
      }

      this.error = "";
      const filtros = {
        ...(this.nombre && { nombre: this.nombre }),
        ...(this.email && { correo: this.email }),
        ...(this.apellidoPaterno && { apellido_paterno: this.apellidoPaterno }),
        ...(this.apellidoMaterno && { apellido_materno: this.apellidoMaterno }),
        ...(this.telefono && { telefono: this.telefono }),
        ...(this.rfc && { rfc: this.rfc }),
        ...(this.tarjeta && { cuenta: this.tarjeta }),
        ...(this.fechaNacimiento && { fecha_nacimiento: this.fechaNacimiento }),
        ...(this.producto_aseguradora && {
          producto_aseguradora: this.producto_aseguradora,
        }),
      };
      if (Object.keys(filtros).length < 1) {
        this.error = "Debes ingresar al menos un filtro";
        this.formularioValido = false;
        return;
      } else this.$emit("filtrosModificados", filtros);
    },
    getProductosAseguradora() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("v1/producto/productosAseguradora", config)
        .then((response) => {
          this.productos_aseguradora = response.data.data.productos;
        });
    },

    getRfc() {
      if (!this.shouldCalculateRfc) return;

      try {
        var partesFecha = this.fechaNacimiento.split("-");
        // Asignar las partes a las variables correspondientes
        var year = partesFecha[0];
        var month = partesFecha[1];
        var day = partesFecha[2];

        this.rfc = RfcFacil.forNaturalPerson({
          name: this.nombre,
          firstLastName: this.apellidoPaterno,
          secondLastName: this.apellidoMaterno,
          day: day,
          month: month,
          year: year,
        });
      } catch (error) {
        //Ignore
      }
    },
    userOnCall() {
      if (localStorage.getItem("onCall")) {
        const item = JSON.parse(localStorage.onCall);
        this.telefono = item.telefono;

        if (item.data.total > 1) {
          let info = "El telefono entrante esta asociado a los usuarios:\n";
          for (let i = 0; i < item.data.tipoDeProducto.length; i++) {
            const e = item.data.tipoDeProducto[i];
            info +=
              "Nombre: " +
              e.nombre +
              "\nProducto Aseguradora: " +
              e.productoAseguradora +
              "\n";
          }
          setTimeout(() => {
            alert(info);
          }, 2000);
        }
        localStorage.removeItem("onCall");
      }
    },
  },
  watch: {
    producto_aseguradora() {
      this.tarjeta = "";
    },

    nombre() {
      this.getRfc();
    },

    apellidoPaterno() {
      this.getRfc();
    },

    apellidoMaterno() {
      this.getRfc();
    },

    fechaNacimiento() {
      this.getRfc();
    },
  },
  mounted() {
    this.getProductosAseguradora();
    this.userOnCall();
  },
};
</script>
